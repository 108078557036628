@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Satisfy&display=swap");
/* font-family: 'Dancing Script', cursive;
font-family: 'Satisfy', cursive; */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
    scrollbar-gutter: stable;
  }
}

@layer components {
  @page {
    size: A4;
    margin: 15mm;
    @bottom-right {
      page-number: "Page :" counter(page);
    }
  }

  .outline-v2 * {
    outline: solid rgb(0 0 0 / 0.5) 1px;
  }

  .remove-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  @page {
    size: A4;
    margin: 10mm;
    @bottom-right {
      page-number: "Page :" counter(page);
    }
  }

  .remove-scroll-bar::-webkit-scrollbar {
    display: none;
  }

  .full-width-div {
    width: calc(100vw - 20px);
    position: relative;
    margin-left: calc(-50vw - -10px);
    left: 50%;
  }

  input[type="file"]::file-selector-button {
    @apply mr-2 rounded-sm border-none bg-gray-50 text-gray-800 ring-1 ring-gray-900/30;
  }

  input[type="file"]::file-selector-button:hover {
    @apply ring-gray-900/50;
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 20px;
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

.cbg {
  background-color: hsla(227, 100%, 71%, 1);
  background-image: radial-gradient(at 29% 13%, hsla(240, 100%, 78%, 1) 0px, transparent 50%),
    radial-gradient(at 9% 43%, rgba(255, 229, 219, 0.476) 0px, transparent 50%),
    radial-gradient(at 39% 78%, hsla(231, 97%, 54%, 1) 0px, transparent 50%);
  background-attachment: fixed;
}

.font-Dancing {
  font-family: "Dancing Script", cursive;
}
.font-Satisfy {
  font-family: "Satisfy", cursive;
}
