.container {
  --color: var(--dark, blue);
  --speed: 200ms;
}

.radio {
  appearance: none;
  position: absolute;
}

.label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  user-select: none;
  position: relative;
  transition: color var(--speed) ease-out;
}

.check-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  aspect-ratio: 1 / 1;
  opacity: 0.9;
  border: 2px solid var(--color);
  border-radius: 50%;
}

.box {
  aspect-ratio: 1;
  height: 0.5rem;
  opacity: 0;
  transition: opacity var(--speed) ease-out;
  background: var(--color);
  aspect-ratio: 1 / 1;
  border-radius: 50%;
}

.radio:checked + .label .check-box .box {
  opacity: 0.9;
}

.radio:enabled:not(:checked):is(:hover, :focus) + .label .check-box .box {
  opacity: 0.3;
}

.radio:enabled:not(:checked):is(:hover, :focus) + .label {
  color: var(--gray-900, black);
}

.radio:disabled + :is(.label, .check-box) {
  cursor: default;
}
